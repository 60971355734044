<template>
    <div v-if="product.count" class="backdrop-count">
        <div class="count">{{ product.count }}</div>
    </div>
</template>

<script>
export default {
    props: ["product"],
};
</script>

<style lang="scss" scoped>
.backdrop-count {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    line-height: 56px;
    color: #fff;
    font-family: "Nunito Sans Black";
    pointer-events: none;
}
</style>

<template>
    <button class="btn-back">
        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.52639 1.4209L1.78955 8.99985L8.52639 16.5788"
                stroke="#C8C8C8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </button>
</template>

<style lang="scss" scoped>
.btn-back {
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-box-shadow: 1px 1px 6px rgba(0, 45, 29, 0.05);
    box-shadow: 1px 1px 6px rgba(0, 45, 29, 0.05);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus {
        background: #eee;
        transition: 0.2s ease 0.2s;
        svg {
            path {
                stroke: #fff;
                transition: 0.2s ease 0.2s;
            }
        }
    }
}
</style>

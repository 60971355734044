<template>
    <div class="btn-mobile">
        <button type="button" class="btn-mobile__burger"><span></span></button>
    </div>
</template>

<style scoped>
.btn-mobile {
    display: none;
}
@media (max-width: 768px) {
    .btn-mobile {
        display: block;
    }
}
.btn-mobile__burger {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    flex-flow: column;
    position: relative;
    width: 18px;
    height: 25px;
    margin: 0 auto;
}
.btn-mobile__burger span {
    background: #c8c8c8;
    width: 100%;
    height: 2px;
    margin: 0 auto;
    border-radius: 2px;
}
.btn-mobile__burger span::before,
.btn-mobile__burger span::after {
    background: inherit;
    box-shadow: inherit;
    content: "";
    width: inherit;
    height: inherit;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: inherit;
}
.btn-mobile__burger span::before {
    top: 6px;
}
.btn-mobile__burger span::after {
    bottom: 6px;
}
</style>

<template>
    <header class="header" :class="$route.meta.headerClass">
        <div class="header__container">
            <a href="/" class="header__logo"
                ><div class="header__name"></div>
                <div class="header__halal"></div
            ></a>
            <div class="header__menu">
                <ul class="header__items">
                    <template v-if="$route.path === '/menu'">
                        <li
                            class="header__item"
                            v-for="page in pages.slice(0, 3).concat(pages.slice(-1).concat(pages.slice(5, 6)))"
                            :key="page.id"
                        >
                            <template v-if="page.name === 'Скачать приложение'">
                                <a :href="page.to">{{ page.name }}</a>
                            </template>
                            <template v-else>
                                <RouterLink :to="page.to" activeClass="active" exactActiveClass="active">{{
                                    page.name
                                }}</RouterLink>
                            </template>
                        </li>
                    </template>
                    <template v-else>
                        <li class="header__item" v-for="page in pages.slice(3)" :key="page.id">
                            <template v-if="page.name === 'Вакансии'">
                                <a :href="page.to" target="_blank">{{ page.name }}</a>
                            </template>
                            <template v-else>
                                <RouterLink :to="page.to" activeClass="active" exactActiveClass="active">{{
                                    page.name
                                }}</RouterLink>
                            </template>
                        </li>
                    </template>
                </ul>
            </div>
            <lang-select />
            <btn-mobile @click="isMobile = true" />
            <btn-profile v-if="$route.path === '/menu'" @click="isLoginModal = true" />
        </div>
    </header>
    <the-mobile :isMobile="isMobile" @close="isMobile = false" />
    <login-modal v-if="isLoginModal" @click="isLoginModal = false" @close="isLoginModal = false" />
</template>

<script>
import LoginModal from "./modals/LoginModal.vue";
import TheMobile from "./TheMobile.vue";
import BtnMobile from "./UI/BtnMobile.vue";
import BtnProfile from "./UI/BtnProfile.vue";
import LangSelect from "./UI/LangSelect.vue";
export default {
    components: { TheMobile, LangSelect, BtnMobile, BtnProfile, LoginModal },
    data() {
        return {
            isMobile: false,
            isLoginModal: false,
            pages: [
                { id: 0, name: "На главную", to: "/" },
                { id: 1, name: "Скачать приложение", to: "#" },
                { id: 2, name: "Условия доставки", to: "/delivery" },
                { id: 3, name: "Акции", to: "/stocks" },
                { id: 4, name: "Туристам", to: "/tourists" },
                { id: 5, name: "Рестораны", to: "/restaraunts" },
                { id: 6, name: "Доставка", to: "/menu" },
                { id: 7, name: "О нас", to: "/about-us" },
                { id: 8, name: "Вакансии", to: "https://rabota.tubatay.com/" },
                { id: 9, name: "Контакты", to: "/contacts" },
            ],
        };
    },
};
</script>

<style scoped>
.header {
    z-index: 2;
    width: 100%;
    min-height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 24px 24px;
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
}
@media (max-width: 768px) {
    .header {
        min-height: 56px;
        border-radius: 0 0 16px 16px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        background: #fff;
    }
}
.header__container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
@media (max-width: 768px) {
    .header__container {
        justify-content: space-between;
    }
}
.header__logo {
    display: flex;
    align-items: center;
    width: fit-content;
}
@media (max-width: 768px) {
    .header__logo {
        justify-content: center;
        margin: auto;
        position: absolute;
        right: 0;
        left: 0;
    }
}
.header__name {
    background-image: url("@/assets/img/svg/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 144px;
    height: 25.75px;
}
.header__name img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media (max-width: 768px) {
    .header__name {
        display: none;
    }
}
.header__halal {
    background-image: url("@/assets/img/svg/halal.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 28px;
    height: 28px;
    margin-left: 10px;
}
@media (max-width: 768px) {
    .header__halal {
        width: 120px;
        height: 50px;
        margin-left: 0;
        background-image: url("@/assets/img/svg/logo.svg");
    }
}
.header__halal img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
@media (max-width: 768px) {
    .header__menu {
        display: none;
    }
}
.header__items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 75px;
    padding: 10px 0;
}
@media (max-width: 1200px) {
    .header__items {
        margin-left: 35px;
    }
}
.header__item {
    margin-right: 50px;
    padding: 10px 0;
}
.header__item a {
    font-size: 14px;
    line-height: 22px;
    color: #1f2120;
    position: relative;
    font-family: "Nunito Sans SemiBold";
}
.header__item a::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 1px;
    background: #1f2120;
}
.header__item a:hover::before,
.header__item a:focus::before {
    width: 100%;
    transition: 0.2s ease 0.2s;
}
.header__item .active::before {
    width: 100% !important;
}
.header._blur {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
}
.header._blur .header__item a {
    color: #fff;
}
.header._blur .header__item a::before {
    background: #fff;
}
.header._blur .header__name {
    background-image: url("@/assets/img/svg/logo-white.svg");
}
.header._blur .header__halal {
    background-image: url("@/assets/img/svg/halal-white.svg");
}
@media (max-width: 768px) {
    .header._blur .header__halal {
        width: 32px;
        height: 32px;
    }
}
.header._blur .header__arrow {
    background-image: url("@/assets/img/svg/select-white.svg");
}
.header._blur .btn-mobile__burger span {
    background: #fff;
}
</style>

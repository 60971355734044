<template>
    <div class="wrapper">
        <the-header />
        <main class="page">
            <router-view />
        </main>
        <the-footer />
    </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
export default {
    components: { TheHeader, TheFooter },
};
</script>

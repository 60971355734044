<template>
    <button class="btn-close">
        <svg width="11.79" height="11.79" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.10547 1.10535L12.8949 12.8948M12.8949 1.10535L1.10547 12.8948"
                stroke="#C8C8C8"
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    </button>
</template>

<style lang="scss" scoped>
.btn-close {
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-box-shadow: 1px 1px 6px rgba(0, 45, 29, 0.05);
    box-shadow: 1px 1px 6px rgba(0, 45, 29, 0.05);
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus {
        background: #eee;
        transition: 0.2s ease 0.2s;
        svg {
            path {
                stroke: #fff;
                transition: 0.2s ease 0.2s;
            }
        }
    }
}
</style>

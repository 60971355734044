<template>
    <div class="recom-products">
        <h5 class="recom-products__title">{{ title }}</h5>
        <swiper
            :slides-per-view="1"
            :space-between="20"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="recom-products__products"
        >
            <swiper-slide class="recom-products__product" v-for="product in products" :key="product.id">
                <div class="recom-products__container">
                    <div class="recom-products__img">
                        <backdrop-count :product="product" />
                        <img :src="product.image" alt="product" />
                    </div>
                    <div class="recom-products__body">
                        <div class="recom-products__desc">
                            <p>{{ product.intro_text }}</p>
                        </div>
                        <div class="recom-products__price counter" v-if="product.count">
                            <btn-to-cart :symbol="'-'" @click="minusToCart(product)" />
                            <div class="recom-products__cost">{{ product.price * product.count }} ₽</div>
                            <btn-to-cart :symbol="'+'" @click="plusToCart(product)" />
                        </div>
                        <div class="recom-products__price" v-else>
                            <div class="recom-products__cost">{{ product.price }} ₽</div>
                            <btn-to-cart :symbol="'+'" @click="addToCart(product)" />
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import BtnToCart from "@/components/UI/BtnToCart.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import axios from "axios";
import { api } from "@/api/api";
import BackdropCount from "./UI/BackdropCount.vue";
export default {
    props: ["title"],
    components: { Swiper, SwiperSlide, BtnToCart, BackdropCount },
    data() {
        return {
            products: [],
        };
    },
    methods: {
        plusToCart(product) {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            elem.count++;
            localStorage.setItem("cart", JSON.stringify(array));
            product.count = elem.count;
        },
        minusToCart(product) {
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            const index = array.findIndex((item) => item.id === product.id);
            if (elem.count > 1) {
                elem.count--;
            } else {
                array.splice(index, 1);
                elem.count = null;
            }
            localStorage.setItem("cart", JSON.stringify(array));
            product.count = elem.count;
        },
        addToCart(product) {
            console.log(product);
            const array = JSON.parse(localStorage.getItem("cart")) ?? [];
            const elem = array.find((item) => item.id === product.id);
            product.count = 1;
            if (elem) {
                elem.count++;
            } else {
                let productItem = {
                    id: product.id,
                    name: product.real_name,
                    description: product.description,
                    price: product.price,
                    is_popular: true,
                    image: product.image,
                    video: null,
                    picshot: null,
                    thumbnail: product.thumbnail,
                    modifiers: [],
                    modifiers_as_hint: false,
                    energy: product.energy,
                    weight: product.weight,
                    articul: null,
                    weight_measure: "gr",
                    fat: product.fat,
                    proteins: product.proteins,
                    carbohydrates: product.carbohydrates,
                    intro_text: product.intro_text,
                    categoriesId: product.categories.id,
                    categoriesRealName: product.categories.real_name,
                    categoriesImage: product.categories.image,
                    is_favourite: false,
                    count: 1,
                };
                array.push(productItem);
            }
            localStorage.setItem("cart", JSON.stringify(array));
        },
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
        };
    },
    mounted() {
        //Товары
        axios
            .get(api + `products`)
            .then((response) => {
                console.log(response.data);
                this.products = response.data.results;
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style lang="scss" scoped>
.recom-products {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.recom-products__title {
    font-size: 16px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #1f2120;
    text-align: left;
}
@media (max-width: 768px) {
    .recom-products__title {
        font-size: 14px;
    }
}
.recom-products__products {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: -10px -20px 0;
    padding: 20px;
}
.recom-products__product {
    width: 10% !important;
    min-width: 344px;
    height: 144px;
    border-radius: 16px;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    overflow: hidden;
}
@media (max-width: 768px) {
    .recom-products__product {
        min-width: 264px;
    }
}
.recom-products__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.recom-products__img {
    min-width: 144px;
    height: 144px;
    overflow: hidden;
    position: relative;
    .backdrop-count {
        border-radius: 16px 0 0 16px;
    }
}
@media (max-width: 768px) {
    .recom-products__img {
        min-width: 104px;
        height: 104px;
    }
}
.recom-products__img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.recom-products__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 15px;
    flex: 1 1 auto;
}
.recom-products__desc {
    font-size: 14px;
    font-family: var(--font-main);
    line-height: 24px;
    color: #1f2120;
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
@media (max-width: 768px) {
    .recom-products__desc {
        font-size: 12px;
        font-family: "Nunito Sans ExtraLight";
        line-height: 16px;
    }
}
.recom-products__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.recom-products__cost {
    font-size: 18px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #1f2120;
}
@media (max-width: 768px) {
    .recom-products__cost {
        font-size: 14px;
    }
}
.recom-products__btn {
    width: 40px;
    height: 40px;
    border-radius: 16px;
    font-size: 18px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #fff;
    background: var(--green);
}
@media (max-width: 768px) {
    .recom-products__btn {
        width: 32px;
        height: 32px;
        border-radius: 12px;
        font-size: 14px;
    }
}
</style>

<template>
    <div class="mobile _mask" :class="{ _show: isMobile }">
        <div class="mobile__block" :class="{ '_active-move': isMobile }">
            <div class="mobile__close">
                <div class="mobile__btn-close" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.10547 1.10535L12.8949 12.8948M12.8949 1.10535L1.10547 12.8948"
                            stroke="#fff"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
            </div>
            <div class="mobile__line"></div>
            <div class="mobile__menu">
                <ul>
                    <li v-for="page in pages" :key="page.id">
                        <template v-if="page.name === 'Вакансии' || page.name === 'Университет'">
                            <a :href="page.to" class="mobile__menu-item">{{ page.name }}</a>
                        </template>
                        <template v-else>
                            <RouterLink
                                :to="page.to"
                                activeClass="active"
                                exactActiveClass="active"
                                class="mobile__menu-item"
                                @click="$emit('close')"
                                >{{ page.name }}</RouterLink
                            >
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["isMobile"],
    data() {
        return {
            pages: [
                { id: 1, name: "Акции", to: "/stocks" },
                { id: 2, name: "Туристам", to: "/tourists" },
                { id: 3, name: "Рестораны", to: "/restaraunts" },
                { id: 4, name: "Доставка", to: "/menu" },
                { id: 5, name: "О нас", to: "/about-us" },
                { id: 6, name: "Вакансии", to: "https://rabota.tubatay.com/" },
                { id: 7, name: "Университет", to: "https://tisbi.tubatay.com/" },
                { id: 8, name: "Контакты", to: "/contacts" },
            ],
        };
    },
};
</script>

<style scoped>
.mobile__block {
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: var(--green);
    position: absolute;
    z-index: 999;
    left: -100%;
    top: 0;
    display: flex;
    flex-direction: column;
    opacity: 0;
}
.mobile__close {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
}
.mobile__btn-close {
    padding: 5px;
}
.mobile__line {
    width: 100%;
    height: 1px;
    background: rgba(236, 244, 239, 0.2);
}
.mobile__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 30px 80px;
    height: 100%;
}
.mobile__menu ul {
    flex: 1 1 auto;
    margin-top: 1rem;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.mobile__menu ul li {
    padding: 20px 0;
    text-align: right;
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
}
.mobile__menu-item {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Nunito Sans Bold";
}
.mobile__menu-item img[alt="user"] {
    width: 16px;
    height: 20px;
    margin-right: 10px;
}
.mobile__logo {
    width: 48px;
    height: 48px;
    margin-top: 30px;
}
.mobile__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            title: "Главная",
            headerClass: "_blur",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/stocks",
        name: "stocks",
        component: () => import("../views/StocksView.vue"),
        meta: {
            title: "Акция",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/stock/:id",
        name: "stock",
        component: () => import("../views/StockIdView.vue"),
        meta: {
            title: "Акция",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/tourists",
        name: "tourists",
        component: () => import("../views/TouristsView.vue"),
        meta: {
            title: "Туристам",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/restaraunts",
        name: "restaraunts",
        component: () => import("../views/RestarauntsView.vue"),
        meta: {
            title: "Адреса ресторанов",
            headerClass: "_delivery",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/menu",
        name: "menu",
        component: () => import("../views/MenuView.vue"),
        meta: {
            title: "Доставка",
            headerClass: "_delivery",
            indexCategory: 0,
            refName: "На главную",
            refTo: "/",
        },
    },
    {
        path: "/about-us",
        name: "about-us",
        component: () => import("../views/AboutUsView.vue"),
        meta: {
            title: "О нас",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("../views/ContactsView.vue"),
        meta: {
            title: "Контакты",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/delivery",
        name: "delivery",
        component: () => import("../views/DeliveryView.vue"),
        meta: {
            title: "Условия доставки",
            headerClass: "_delivery",
            indexCategory: 0,
            refName: "На главную",
            refTo: "/",
        },
    },
    {
        path: "/discounts",
        name: "discounts",
        component: () => import("../views/DiscountsView.vue"),
        meta: {
            title: "Скидки",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/invest",
        name: "invest",
        component: () => import("../views/InvestView.vue"),
        meta: {
            title: "Инвестиции",
            headerClass: "",
            indexCategory: 0,
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/details",
        name: "details",
        component: () => import("../views/DetailsView.vue"),
        meta: {
            title: "Реквизиты организаций",
            headerClass: "_delivery",
            indexCategory: 0,
            refName: "На главную",
            refTo: "/",
        },
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("../views/AdminView.vue"),
        meta: {
            title: "Админ",
            headerClass: "_delivery",
            indexCategory: 0,
            refName: "На главную",
            refTo: "/",
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    document.title = "Tюбетей | " + to.meta.title;
    next();
});

export default router;

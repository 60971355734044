<template>
    <div class="modal" id="modal-cart">
        <div class="modal-backdrop" @click="$emit('close')"></div>
        <div class="modal-block" @click.stop>
            <button class="modal-btn md" @click="$emit('close')">
                <img src="@/assets/img/close.webp" alt="close" />
            </button>
            <div class="modal-body">
                <div class="modal-body__container">
                    <template v-if="totalCountCart()">
                        <buttons-toggle nameInput="item" v-if="isPayment" @tab-show="tabShow" />
                        <template v-if="isOrderProducts">
                            <h3 class="count-products">
                                {{ totalCountCart() }} товара на сумму {{ totalPriceCart() }}₽
                            </h3>
                            <div class="products">
                                <template v-for="item in items" :key="item.id">
                                    <div class="product" v-if="item.count">
                                        <div class="product__img">
                                            <img :src="item.image" alt="image" />
                                        </div>
                                        <div class="product__body">
                                            <div class="product__head">
                                                <h4 class="product__title">{{ item.name }}</h4>
                                                <span class="product__weight">{{ item.weight }} г</span>
                                            </div>
                                            <div class="product__bottom">
                                                <div class="product__price">{{ item.price * item.count }} ₽</div>
                                                <div class="counter">
                                                    <span class="minus" @click="$emit('minusToCart', item)">-</span>
                                                    <span class="count">{{ item.count }}</span>
                                                    <span class="plus" @click="$emit('plusToCart', item)">+</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="strokes">
                                <div class="stroke">
                                    <span
                                        ><img :src="require(`@/assets/img/svg/devices.svg`)" alt="img" /> Количество
                                        приборов</span
                                    >
                                    <div class="counter">
                                        <span class="minus" @click="countDevDecr">-</span>
                                        <span class="count">{{ countDev }}</span>
                                        <span class="plus" @click="countDevIncr">+</span>
                                    </div>
                                </div>
                                <div class="stroke" @click="isInputPromocode = true">
                                    <div class="inputPromocode" v-if="isInputPromocode">
                                        <div class="block-input">
                                            <input
                                                type="text"
                                                name="promocode"
                                                placeholder="Введите промокод"
                                                v-model="inputPromocode"
                                                :maxlength="maxLength"
                                                :style="
                                                    inputPromocode.length < maxLength
                                                        ? 'color:#333'
                                                        : checkPromocode
                                                        ? 'color:#D66C56'
                                                        : 'color:#3B7351'
                                                "
                                            />
                                            <span v-if="checkPromocode" class="error">Неверный промокод</span>
                                            <span v-if="isDiscount" class="discount"
                                                >Скидка 50% при заказе от 599р</span
                                            >
                                        </div>
                                        <button
                                            v-if="!isDiscount"
                                            @click="
                                                inputPromocode === promocode
                                                    ? (isDiscount = true)
                                                    : (isDiscount = false)
                                            "
                                            class="btn btn-apply"
                                        >
                                            Применить
                                        </button>
                                        <button
                                            v-else
                                            @click="(isDiscount = false), (inputPromocode = '')"
                                            class="btn btn-remove"
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                    <template v-else>
                                        <span>Ввести промокод</span>
                                        <btn-back />
                                    </template>
                                </div>
                            </div>
                            <recom-products title="Добавить к заказу?" />
                            <div class="total-price">
                                <div class="total-price__shipping">
                                    <span>Доставка</span>
                                    <span>99 ₽</span>
                                </div>
                                <div class="total-price__total">
                                    <span>Итого</span>
                                    <span>{{ totalPriceCart() }} ₽</span>
                                </div>
                                <btn-name btnName="Оформить заказ" @click="(isOrderProducts = false), sendOrder()" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="block-payment" v-if="isPayment">
                                <div class="body">
                                    <h4 class="head-title">Время доставки</h4>
                                    <input type="hidden" v-model="shipping_type" />
                                    <div class="time-shipping">
                                        <button
                                            class="time-shipping__btn"
                                            v-for="shipping in shippings"
                                            :key="shipping.id"
                                            :class="shipping_type === shipping.title ? 'active' : ''"
                                            @click="shipping_type = shipping.title"
                                        >
                                            {{ shipping.title }}
                                        </button>
                                    </div>
                                    <input type="hidden" v-model="time_interval" />
                                    <div class="time-interval">
                                        <div
                                            class="time-interval__time"
                                            v-for="interval in intervals"
                                            :key="interval.id"
                                            :class="time_interval === interval.time ? 'active' : ''"
                                            @click="time_interval = interval.time"
                                        >
                                            {{ interval.time }}
                                        </div>
                                    </div>
                                </div>
                                <div class="body" v-if="activeTab === 2">
                                    <h4 class="head-title">Способ получения</h4>
                                    <input type="hidden" v-model="delivery_type" />
                                    <div class="methods">
                                        <div
                                            class="methods__block"
                                            :class="delivery_type === label.value ? 'active' : ''"
                                            v-for="label in labels"
                                            :key="label.id"
                                            @click="delivery_type = label.value"
                                        >
                                            <div class="methods__img">
                                                <img :src="require(`@/assets/img/3d/${label.img}`)" alt="img" />
                                            </div>
                                            <h4 class="methods__title" :value="label.value">{{ label.name }}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="body">
                                    <h4 class="head-title">Оплата</h4>
                                    <div class="pays">
                                        <div class="pay">
                                            <img src="@/assets/img/pays/pay-card.png" alt="pay" />
                                        </div>
                                        <div class="pay">
                                            <img src="@/assets/img/pays/no-pay.png" alt="pay" />
                                        </div>
                                        <div class="pay">
                                            <img src="@/assets/img/pays/add-card.png" alt="pay" />
                                        </div>
                                    </div>
                                </div>
                                <div class="body">
                                    <h4 class="head-title">Комментарий к заказу</h4>
                                    <textarea
                                        name="comment-order"
                                        id="comment-order"
                                        placeholder="Например, не добавлять соус в кыстыбургер"
                                    ></textarea>
                                </div>
                                <div class="total-price">
                                    <div class="total-price__shipping">
                                        <span>{{ totalCountCart() }} товара на сумму</span>
                                        <span>{{ totalPriceCart() }} ₽</span>
                                    </div>
                                    <div class="total-price__total">
                                        <span>К оплате</span>
                                        <span>{{ totalPriceCart() }} ₽</span>
                                    </div>
                                    <btn-name btnName="Оформить заказ" @click="isPayment = false" />
                                </div>
                            </div>
                            <div class="status-order" v-else>
                                <div class="number-order">Заказ #56</div>
                                <h4 class="status-title">Статус заказа</h4>
                                <steps-order />
                                <div class="personal-offers__block">
                                    <div class="personal-offers__body">
                                        <div class="personal-offers__stocks">
                                            Скидка 20% на первый заказ<br />
                                            в приложении
                                        </div>
                                        <div class="personal-offers__promocode">Промокод: МИНУС20</div>
                                        <button class="personal-offers__btn btn">Скачать</button>
                                    </div>
                                    <div class="personal-offers__img">
                                        <img src="@/assets/img/stocks/1.png" alt="img" />
                                    </div>
                                </div>
                                <h4 class="status-title">Детали заказа</h4>
                                <div class="products">
                                    <template v-for="item in items" :key="item.id">
                                        <div class="product" v-if="item.count">
                                            <div class="product__img">
                                                <img :src="item.image" alt="image" />
                                            </div>
                                            <div class="product__body">
                                                <div class="product__head">
                                                    <h4 class="product__title">{{ item.name }}</h4>
                                                    <span class="product__weight">{{ item.weight }} г</span>
                                                </div>
                                                <div class="product__bottom">
                                                    <div class="product__price">{{ item.price }} ₽</div>
                                                    <span class="count">{{ item.count }} шт</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="address">
                                    <div class="address__title">Адрес самовывоза</div>
                                    <div class="address__street">
                                        <span>Спартаковская, 2</span>
                                        <span>С собой</span>
                                    </div>
                                </div>
                                <div class="time-order">
                                    <div class="time-order__order">
                                        <span>Время заказа</span>
                                        <span>26.02.2024 в 17:20</span>
                                    </div>
                                    <div class="time-order__done">
                                        <span>Время выдачи</span>
                                        <span>26.02.2024 в 18:00</span>
                                    </div>
                                </div>
                                <div class="total-price">
                                    <div class="total-price__shipping">
                                        <span>{{ totalCountCart() }} товара на сумму</span>
                                        <span>99 ₽</span>
                                    </div>
                                    <div class="total-price__t-coins">
                                        <span>Начислим Т-коинов</span>
                                        <span>+90</span>
                                    </div>
                                    <div class="total-price__total">
                                        <span>Итого</span>
                                        <span>{{ totalPriceCart() }} ₽</span>
                                    </div>
                                    <button class="btn-leave-review btn btn-bn">Оставить отзыв</button>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <div class="modal-body__empty">
                            <div class="modal-body__cart-img"><img src="@/assets/img/3d/cart.png" alt="cart" /></div>
                            <div class="modal-body__message">
                                <p>В корзине пока</p>
                                <p>ничего нет</p>
                            </div>
                        </div>
                        <button class="modal-body__btn disabled">Добавьте товары на 799 ₽</button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import RecomProducts from "../RecomProducts.vue";
import StepsOrder from "../StepsOrder.vue";
import BtnBack from "../UI/BtnBack.vue";
import BtnName from "../UI/BtnName.vue";
import ButtonsToggle from "../UI/ButtonsToggle.vue";
export default {
    components: { ButtonsToggle, RecomProducts, BtnBack, BtnName, StepsOrder },
    props: ["totalCountCart", "totalPriceCart"],
    data() {
        return {
            items: null,
            totalPrice: 0,
            isInputPromocode: false,
            inputPromocode: "",
            isDiscount: false,
            maxLength: 6,
            promocode: "FELKME",
            isOrderProducts: true,
            isPayment: true,
            delivery_type: "pickup_on_tray",
            shipping_type: "Привезти позже",
            time_interval: "16:30 - 17:00",
            chopsticks: 0,
            now: new Date(),
            now2: new Date(),
            token: localStorage.getItem("token"),
            labels: [
                { id: 1, name: "В зале", img: "with_him.png", value: "pickup_on_tray" },
                { id: 2, name: "С собой", img: "in_hall.png", value: "packed_pickup" },
            ],
            orderId: localStorage.getItem("orderId") ?? null,
            shop_id: 2,
            countDev: 1,
            activeTab: 1,
            shippings: [
                { id: 1, title: "Как можно скорее" },
                { id: 2, title: "Привезти позже" },
            ],
            intervals: [
                { id: 1, time: "16:30 - 17:00" },
                { id: 2, time: "17:00 - 17:30" },
                { id: 3, time: "17:30 - 18:00" },
            ],
        };
    },
    methods: {
        tabShow(tab) {
            this.activeTab = tab;
        },
        countDevDecr() {
            this.countDev > 1 ? this.countDev-- : (this.countDev = 1);
        },
        countDevIncr() {
            this.countDev++;
        },
        sendOrder() {
            let headers = null;
            let items = [];
            let discount_code = this.promocode.code ?? null;

            if (this.token) {
                headers = {
                    Authorization: "Token " + this.token,
                };
            }

            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].type !== "discounted") {
                    let item = {};
                    item.quantity = this.items[i].count;
                    item.id = this.items[i].id;
                    items.push(item);
                }
            }

            if (items.length !== 0) {
                axios
                    .post(
                        `https://admin.tcode.online/api/projects/tubatay/orders/`,
                        {
                            shop_id: this.shop_id,
                            items: items,
                            //bonuses: this.bonuses,
                            delivery_type: this.delivery_type,
                            express: true,
                            discount_code: discount_code,
                            //order_date: this.order_date,
                            order_time_from:
                                String(this.now.getHours()).padStart(2, "0") +
                                ":" +
                                String(this.now.getMinutes()).padStart(2, "0"),
                            order_time_to:
                                String(this.now2.getHours()).padStart(2, "0") +
                                ":" +
                                String(this.now2.getMinutes()).padStart(2, "0"),
                            app_version: "Тюбетей",
                        },
                        {
                            headers: headers,
                        }
                    )
                    .then((response) => {
                        if (response.data.success) {
                            localStorage.setItem("orderId", response.data.order.id);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    computed: {
        checkPromocode() {
            return this.inputPromocode !== this.promocode && this.inputPromocode.length === this.maxLength;
        },
    },
    mounted() {
        if (localStorage.getItem("cart")) {
            this.items = JSON.parse(localStorage.getItem("cart"));
        }
        console.log(this.items);
        console.log(this.orderId);
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "univer";
        } else {
            this.cafe_slug = "it-park";
        }
        let self = this;
        if (this.orderId) {
            axios
                .get(`https://admin.tcode.online/api/projects/tubatay/orders/` + this.orderId)
                .then(function (response) {
                    console.log(self.cafe_slug);
                    self.orderObj = response.data;
                    axios
                        .get("http://127.0.0.1:5000/payment/create/?amount=" + self.orderObj.full_price * 100)
                        .then((response) => {
                            self.onProccess = false;
                            console.log("TERMINAL: " + response.data);
                            if (response.data.success) {
                                console.log("TERMINAL: SUCCESS = " + response.data.success);
                                self.paymentError = false;
                                axios
                                    .post(
                                        `https://admin.tcode.online/api/projects/tubatay/shops/${self.cafe_slug}/order-confirm/`,
                                        {
                                            order_id: self.orderId,
                                            comment: "Заказ сделан через сайт Тюбетей",
                                        }
                                    )
                                    .then((response) => {
                                        self.onProccess = false;
                                        self.orderObj = response.data;

                                        axios.get(
                                            "http://127.0.0.1:5000/receipt/print/?text=" + self.orderObj.receipt_text
                                        );

                                        setTimeout(function () {
                                            location.href = "/";
                                            localStorage.clear();
                                        }, 12000);
                                    })
                                    .catch((err) => {
                                        console.log("API: ERROR = " + err);
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log("TERMINAL: ERROR = " + err);
                            self.paymentError = true;
                            self.onProccess = false;
                        });
                });
        }
        //точки продаж
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/`)
            .then((response) => {
                console.log(response.data);
                for (let i = 0; i < response.data.length; i++) {
                    if (this.cafe_slug === response.data[i].code) {
                        this.shop_id = response.data[i].id;
                        console.log(this.shop_id);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style lang="scss" scoped>
.modal {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: flex-end;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: #d9f5e4;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--green);
        border-radius: 5px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:hover {
        opacity: 0.5;
    }
    @media (max-width: 768px) {
        justify-content: center;
        overflow-x: hidden;
    }
    .inputPromocode {
        width: 100%;
        display: flex;
        .block-input {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            position: relative;
            .error {
                font-size: 8px;
                line-height: 12px;
                text-align: center;
                color: #d66c56;
                font-family: "Nunito Sans ExtraLight";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 20%;
                display: flex;
                align-items: center;
            }
            .discount {
                font-size: 10px;
                line-height: 16px;
                color: #7a7a7a;
                margin-top: -5px;
            }
        }
        input {
            font-size: 14px;
            line-height: 24px;
            color: #333;
            flex: 1 1 auto;
            font-family: "Nunito Sans";
            &::placeholder {
                color: #c8c8c8;
            }
        }
        .btn {
            min-width: 120px;
            max-width: 120px;
            height: 36px;
            border-radius: 16px;
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            line-height: 22px;
        }
        .btn-apply {
            background: #d9f5e4;
            color: var(--green);
            &:hover,
            &:focus {
                background: #c5f7d9;
                transition: 0.2s ease 0.2s;
            }
        }
        .btn-remove {
            background: #f4f4f4;
            color: #c8c8c8;
            &:hover,
            &:focus {
                background: #c8c8c8;
                color: #f4f4f4;
                transition: 0.2s ease 0.2s;
            }
        }
    }
    .count-products {
        font-family: "Nunito Sans Bold";
        font-size: 18px;
        line-height: 24px;
        color: #1f2120;
    }
    .products {
        overflow-x: hidden;
        min-height: 300px;
        max-height: 300px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #d9f5e4;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--green);
            border-radius: 5px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.5;
        }
        .product {
            width: 100%;
            height: 144px;
            border-radius: 16px;
            overflow: hidden;
            box-shadow: 0 3px 35px rgb(0, 45, 29, 0.05);
            margin-top: 10px;
            display: flex;
            &__img {
                min-width: 144px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                padding: 10px;
                flex: 1 1 auto;
            }

            &__head {
                flex: 1 1 auto;
            }

            &__title {
                font-size: 14px;
                line-height: 24px;
                color: #1f2120;
                white-space: pre-line;
            }

            &__weight {
                font-size: 10px;
                line-height: 16px;
                color: #bebebe;
            }

            &__bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__price {
                font-family: "Nunito Sans Bold";
                font-size: 18px;
                line-height: 24px;
                color: #1f2120;
            }
        }
    }
    .counter {
        background: #d9f5e4;
        min-width: 86px;
        max-width: 86px;
        height: 36px;
        border-radius: 16px;
        font-family: "Nunito Sans Bold";
        font-size: 16px;
        line-height: 24px;
        color: var(--green);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        .minus,
        .plus {
            cursor: pointer;
        }
    }
    .strokes {
        .stroke {
            background: #fff;
            box-shadow: 0 3px 35px rgb(0, 45, 29, 0.05);
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            line-height: 22px;
            color: #1f2120;
            padding: 10px 15px;
            .btn-back {
                position: unset;
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
            img {
                margin-right: 5px;
            }
        }
    }
    .total-price {
        font-size: 14px;
        line-height: 24px;
        color: #1f2120;
        padding-bottom: 35px;
        &__shipping,
        &__total {
            display: flex;
            justify-content: space-between;
        }
        &__total {
            margin-top: 10px;
        }
        .btn {
            width: 100%;
            margin-top: 10px;
        }
    }
    .modal-backdrop {
        width: inherit;
        height: inherit;
        background: rgb(31, 33, 32, 0.5);
        position: absolute;
    }
    .modal-block {
        width: fit-content;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        right: -100%;
        animation: showModal 0.2s forwards 0.2s;
        @media (max-width: 768px) {
            z-index: 1;
        }
        @keyframes showModal {
            0% {
                opacity: 0;
                right: -100%;
            }
            100% {
                opacity: 1;
                right: 0;
            }
        }
    }
    .modal-btn {
        background: none;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 1px 1px 6px 0 rgba(0, 45, 29, 0.05);
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
    }
    .modal-body {
        background-color: #fff;
        width: 448px;
        height: 100vh;
        border-radius: 16px 0 0 16px;
        box-shadow: 1px 3px 25px 0 rgba(0, 45, 29, 0.1);
        padding: 35px 25px;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #d9f5e4;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--green);
            border-radius: 5px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.5;
        }
        @media (max-width: 768px) {
            width: 100vw;
            height: 100%;
            border-radius: 0;
        }
        &__container {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1 1 auto;
        }

        &__cart-img {
            width: 242px;
            height: 235px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: drop-shadow(12px 30px 30.8px #eee);
            }
            @media (max-width: 768px) {
                width: 184px;
                height: 179px;
                img {
                    object-fit: contain !important;
                }
            }
        }

        &__message {
            font-family: "Nunito Sans Bold";
            font-size: 16px;
            line-height: 24px;
            color: #000;
            text-align: center;
            margin-top: 20px;
            @media (max-width: 768px) {
                font-size: 14px;
                margin-top: 50px;
            }
        }

        &__btn {
            width: 100%;
            min-height: 56px;
            border-radius: 30px;
            font-family: "Nunito Sans Bold";
            font-size: 16px;
            line-height: 24px;
            background: var(--green);
            color: #fff;
            text-align: center;
            @media (max-width: 768px) {
                width: fit-content;
                margin: 0 auto;
                padding: 0 30px;
                font-size: 14px;
                min-height: 48px;
            }
        }
    }
    .disabled {
        background: #c8c8c8;
        color: #7a7a7a;
    }
    .block-payment {
        .body {
            margin-top: 20px;
        }
        .head-title {
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            text-align: left;
        }
        .time-shipping {
            margin-top: 5px;
            &__btn {
                width: 50%;
                height: 40px;
                border-radius: 10px;
                box-shadow: 1px 3px 25px rgb(0, 45, 29, 0.05);
                background: #fff;
                font-size: 14px;
                line-height: 22px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
                &:hover,
                &:focus {
                    border: 1px solid var(--green);
                    color: var(--green);
                    transition: 0.2s ease 0.2s;
                }
            }
        }
        .active {
            border: 1px solid var(--green);
            color: var(--green) !important;
            .methods__title {
                color: var(--green) !important;
            }
        }
        .time-interval {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            &__time {
                width: 30%;
                height: 40px;
                border-radius: 10px;
                box-shadow: 0 3px 35px rgb(0, 45, 29, 0.05);
                background: #fff;
                font-size: 14px;
                line-height: 22px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover,
                &:focus {
                    border: 1px solid var(--green);
                    color: var(--green);
                    transition: 0.2s ease 0.2s;
                }
            }
        }
        .methods {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            &__block {
                width: 49%;
                height: 112px;
                border-radius: 10px;
                box-shadow: 1px 3px 25px rgb(0, 45, 29, 0.05);
                background: #fff;
                cursor: pointer;
                &:hover,
                &:focus {
                    border: 1px solid var(--green);
                    color: var(--green);
                    transition: 0.2s ease 0.2s;
                }
            }

            &__img {
                width: 84px;
                height: 84px;
                margin: 0 auto;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__title {
                font-size: 12px;
                line-height: 18px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
            }
        }
        .total-price {
            margin-top: 10px;
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            &__total {
                font-family: "Nunito Sans Bold";
            }
        }
    }
    .status-order {
        margin-top: -1rem;
        .number-order {
            font-size: 18px;
            line-height: 24px;
            color: #1f2120;
            font-family: "Nunito Sans Bold";
        }
        .status-title {
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            font-family: "Nunito Sans Bold";
            margin-top: 20px;
        }
        .personal-offers {
            &__block {
                height: 144px;
                margin-top: 20px;
            }
            &__body {
                flex: 1 1 auto;
            }
        }
        .products {
            height: 35vh;
        }
        .product {
            &__body {
                padding: 10px 15px 20px;
            }

            &__bottom {
                .count {
                    font-size: 18px;
                    line-height: 24px;
                    color: #1f2120;
                    font-family: "Nunito Sans Bold";
                }
            }
        }
        .address {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 10px;
            &__title {
                font-size: 16px;
                line-height: 24px;
                color: #1f2120;
                font-family: "Nunito Sans Bold";
            }

            &__street {
                display: flex;
                flex-direction: column;
                span {
                    &:first-child {
                        font-size: 14px;
                        line-height: 24px;
                        color: #1f2120;
                    }
                    &:last-child {
                        font-size: 10px;
                        line-height: 16px;
                        color: #c8c8c8;
                    }
                }
            }
        }
        .time-order {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 10px;
            &__order,
            &__done {
                display: flex;
                flex-direction: column;
                span {
                    &:first-child {
                        font-size: 16px;
                        line-height: 24px;
                        color: #1f2120;
                        font-family: "Nunito Sans Bold";
                    }
                    &:last-child {
                        font-size: 14px;
                        line-height: 24px;
                        color: #1f2120;
                    }
                }
            }
        }
        .total-price {
            margin-top: 10px;
            &__t-coins {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                span {
                    &:last-child {
                        color: var(--green);
                    }
                }
            }
        }
    }
}
.modal {
    .modal-body {
        &__cart-img {
            width: 384px;
            height: 384px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @media (max-width: 768px) {
                height: 344px;
                width: 100%;
                .modal-body__prices {
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    right: 0;
                    width: 97%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 2.5px;
                }
                .modal-body__price {
                    font-size: 12px;
                    line-height: 18px;
                    font-family: "Nunito Sans SemiBold";
                }
            }
        }

        &__bottom {
            padding-top: 10px;
        }

        &__title {
            font-size: 16px;
            line-height: 24px;
            color: #1f2120;
            font-family: "Nunito Sans Bold";
            width: 100%;
            margin-top: 20px;
            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: #1f2120;
            margin-top: 20px;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
                font-family: "Nunito Sans ExtraLight";
            }
        }

        &__list {
            margin-top: 10px;
            li {
                font-size: 10px;
                line-height: 16px;
                color: #c8c8c8;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: transparent;
                background-image: url("@/assets/img/svg/select.svg");
                background-repeat: no-repeat;
                background-position: 0;
                cursor: pointer;
                padding-left: 1rem;
                margin-top: 5px;
            }
        }

        &__prices {
            background: #f4f4f4;
            border-radius: 12px;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            margin-top: 20px;
        }

        &__price {
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            line-height: 22px;
            color: #7a7a7a;
            width: 25%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            cursor: pointer;
            &:hover,
            &:focus {
                background: #fff;
                color: #3b7351;
                transition: 0.2s ease 0.2s;
            }
            &.active {
                background: #fff;
                color: #3b7351;
            }
        }

        &__steps {
            display: flex;
            justify-content: space-between;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: 2px;
                background: #c8c8c8;
            }
        }

        &__step {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            background: #c8c8c8;
            position: relative;
            &.active {
                background: #3b7351;
            }
        }

        &__btn {
            margin-top: 20px;
        }
    }
}
#comment-order {
    width: 100%;
    height: 113px;
    background: #f4f4f4;
    border-radius: 12px;
    margin-top: 5px;
    padding: 10px;
    resize: none;
    &::placeholder {
        font-size: 14px;
        line-height: 24px;
        color: #c8c8c8;
        font-family: "Nunito Sans";
    }
}
</style>

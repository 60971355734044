<template>
    <dialog class="modal" open>
        <div class="block-modal" @click.stop>
            <btn-back v-if="active !== 1" @click="active = 1" />
            <btn-close @click="$emit('close')" />
            <div class="container">
                <h2 class="head-title">{{ headTitle[active - 1] }}</h2>
                <div class="body">
                    <template v-if="active === 1">
                        <swiper
                            :modules="modules"
                            :slides-per-view="1"
                            :space-between="0"
                            @swiper="onSwiper"
                            @slideChange="onSlideChange"
                            :pagination="{ clickable: true }"
                            :autoplay="{ delay: 2500 }"
                        >
                            <swiper-slide v-for="slide in slides" :key="slide.id">
                                <div class="img"><img :src="require(`@/assets/img/3d/${slide.img}`)" alt="img" /></div>
                                <h4 class="title">{{ slide.title }}</h4>
                                <div class="text">{{ slide.text }}</div>
                            </swiper-slide>
                        </swiper>
                    </template>
                    <template v-if="active === 2">
                        <div class="input-phone">
                            <h4 class="input-phone__title">
                                Пожалуйста, введите номер своего<br />
                                телефона, чтобы авторизоваться
                            </h4>
                            <div class="block-input" :style="{ border: !validPhone ? '1px solid var(--red)' : '' }">
                                <span>+7</span>
                                <MaskInput
                                    type="text"
                                    v-model.trim="valuePhone"
                                    mask="### ### ## ##"
                                    placeholder="Номер телефона"
                                />
                            </div>
                            <div class="input-phone__agree">
                                Указывая номер, вы соглашаетесь с офертой<br />
                                и политикой конфиденциальности
                            </div>
                            <div v-if="!validPhone" class="error">
                                <small>Введен некорректный номер телефона</small>
                            </div>
                        </div>
                    </template>
                    <template v-if="active === 3">
                        <div class="input-phone">
                            <h4 class="input-phone__title">
                                Отправили код СМС-сообщением на<br />
                                номер +7 {{ valuePhone }}
                            </h4>
                            <div class="input-phone__numbs">
                                <div class="input-phone__numb" v-for="i in 4" :key="i">
                                    <div class="active" :class="checkCode ? 'valid' : ''" v-if="valueCode[i - 1]">
                                        {{ valueCode[i - 1] }}
                                    </div>
                                    <div class="disc" v-else></div>
                                </div>
                            </div>
                            <input type="hidden" @keydown="onKeydown" v-model.trim="valueCode" maxlength="4" />
                        </div>
                    </template>
                    <template v-if="active === 4">
                        <div class="input-phone">
                            <h4 class="input-phone__title">
                                Пожалуйста, введите<br />
                                свое имя
                            </h4>
                            <div class="block-input">
                                <input type="text" name="name" placeholder="Введите имя" v-model="valueName" />
                            </div>
                        </div>
                    </template>
                </div>
                <btn-name v-if="active === 1" btnName="Войти по телефону" @click="active = 2" />
                <btn-name
                    v-if="active === 2"
                    btnName="Продолжить"
                    :disabled="valuePhone.length > 12 ? false : true"
                    @click="sendPhone()"
                />
                <btn-name
                    v-if="active === 3"
                    :btnName="checkCode ? 'Продолжить' : 'Новый код через 47 сек'"
                    @click="sendCode()"
                    :disabled="checkCode ? false : true"
                />
                <btn-name
                    v-if="active === 4"
                    btnName="Продолжить"
                    @click="auth()"
                    :disabled="valueName.length >= 2 ? false : true"
                />
            </div>
        </div>
    </dialog>
</template>

<script>
import BtnClose from "../UI/BtnClose.vue";
import BtnBack from "../UI/BtnBack.vue";
import BtnName from "../UI/BtnName.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import axios from "axios";
export default {
    components: { BtnName, BtnClose, BtnBack, Swiper, SwiperSlide },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Pagination, Autoplay],
        };
    },
    data() {
        return {
            isBtnBack: false,
            headTitle: ["Войдите в профиль", "Укажите телефон", "Введите код", "Как вас зовут?"],
            slides: [
                {
                    id: 1,
                    img: "t-coin.png",
                    title: "Копи Т-коины",
                    text: `Делай заказ, копи Т-коины и обменивай их\n на продукты за 1₽ в приложении`,
                },
                {
                    id: 2,
                    img: "personalization.png",
                    title: "Персонализация",
                    text: `Получай персональные акции только\n в профиле программы лояльности`,
                },
                {
                    id: 3,
                    img: "mobile-order.png",
                    title: "Мобильный заказ",
                    text: `Чтобы не тратить время в очереди на кассе\n и на выдаче, можешь сделать заказ за 5-8\n минут и забрать его сразу`,
                },
                {
                    id: 4,
                    img: "cashback.png",
                    title: "Повышенный кэшбэк",
                    text: `Чем больше заказов - тем больше кешбэка,\n от 3 до 7 %`,
                },
                {
                    id: 5,
                    img: "note.webp",
                    title: "История заказов",
                    text: `Можно просмотреть историю заказов,\n повторить заказ и видеть свой актуальный\n бонусный счет`,
                },
            ],
            valuePhone: "",
            valueName: localStorage.getItem("name") ?? "",
            active: 1,
            valueCode: "",
            validPhone: true,
            token: localStorage.getItem("token"),
        };
    },
    methods: {
        onKeydown(event) {
            if (this.active === 3) {
                for (let i = 0; i < 10; i++) {
                    if (event.key === `${i}` && this.valueCode.length <= 3) {
                        this.valueCode += `${i}`;
                    }
                }
                if (event.key === "Backspace" || event.key === "Delete") {
                    this.valueCode = this.valueCode.substring(0, this.valueCode.length - 1);
                }
            }
        },
        sendPhone() {
            axios
                .post(`https://admin.tcode.online/api/projects/tubatay/auth/phone/`, {
                    phone: `+7${this.valuePhone}`,
                })
                .then((response) => {
                    console.log(response.data);
                    this.active = 3;
                })
                .catch((error) => {
                    console.log(error);
                    this.validPhone = false;
                });
        },
        sendCode() {
            axios
                .post(`https://admin.tcode.online/api/projects/tubatay/auth/verification/`, {
                    phone: `+7${this.valuePhone}`,
                    code: this.valueCode,
                })
                .then((response) => {
                    console.log(response.data);
                    localStorage.setItem("token", response.data.token);
                    this.active = 4;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        sendName() {
            axios
                .post(`https://admin.tcode.online/api/projects/tubatay/profile/`, {
                    name: this.valueName,
                    headers: {
                        Authorization: "Token " + this.token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        auth() {
            if (this.valueName.length >= 2) {
                this.sendName();
                this.$emit("close");
                localStorage.setItem("name", this.valueName);
                this.$router.push("/admin");
            }
        },
    },
    computed: {
        checkCode() {
            return this.valueCode === "2555" && this.valueCode.length === 4;
        },
    },
    mounted() {
        document.addEventListener("keydown", this.onKeydown);
        console.log(this.valueCode);
    },
};
</script>

<style lang="scss">
@import url("@/assets/scss/modal.scss");
.modal {
    .block-modal {
        height: 528px;
        .container {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            .btn {
                max-width: 224px;
                height: 56px;
                border-radius: 30px;
            }
        }
        .error {
            color: var(--red);
            margin-top: 1rem;
        }
    }
    .img {
        width: 207px;
        height: 194px;
        margin: 30px auto 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .title {
        font-size: 16px;
        line-height: 24px;
        color: #000;
        font-family: "Nunito Sans Bold";
    }
    .text {
        font-size: 14px;
        line-height: 24px;
        color: #1f2120;
        margin-top: 10px;
        white-space: pre;
    }
    .btn {
        margin: 0 auto;
        &[disabled] {
            background: #c8c8c8;
            color: #7a7a7a;
        }
    }
    .swiper {
        padding-bottom: 50px;
        .swiper-pagination {
            bottom: 1.5rem;
            .swiper-pagination-bullet {
                background: #f4f4f4;
                opacity: 0;
                width: 6px;
                height: 6px;
                margin: 0 3px;
            }
            .swiper-pagination-bullet-active {
                background: #c8c8c8;
            }
        }
    }
    .body {
        flex: 1 1 auto;
        .input-phone {
            margin-top: 35px;
            &__title {
                font-size: 16px;
                line-height: 24px;
                color: #000;
                text-align: center;
            }

            .block-input {
                width: 100%;
                height: 56px;
                border-radius: 12px;
                border: 1px solid #c8c8c8;
                margin-top: 30px;
                display: flex;
                align-items: center;
                overflow: hidden;
                span {
                    font-size: 16px;
                    line-height: 24px;
                    color: #1f2120;
                    border-right: 1px solid #f4f4f4;
                    padding: 0 15px 0 20px;
                }
                input {
                    font-size: 16px;
                    line-height: 24px;
                    color: #1f2120;
                    font-family: "Nunito Sans";
                    padding: 0 15px;
                    outline: none;
                    width: 100%;
                    height: 100%;
                    &::placeholder {
                        color: #c8c8c8;
                    }
                }
            }

            &__agree {
                font-size: 10px;
                line-height: 16px;
                color: #c8c8c8;
                text-align: center;
                margin-top: 5px;
            }

            &__numbs {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
            }
            &__numb {
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                .disc {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #c8c8c8;
                }
                .active {
                    font-size: 16px;
                    line-height: 21.82px;
                    font-family: "Nunito Sans Bold";
                    color: var(--red);
                    &.valid {
                        color: var(--green);
                    }
                }
            }
        }
    }
}
</style>

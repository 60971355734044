<template>
    <div class="tabs">
        <div class="tabs-toggle">
            <div v-for="tab in tabs" :key="tab.id" :class="`tab-item item-${tab.id}`" @click="$emit('tabShow', tab.id)">
                <input
                    :id="`${nameInput}-${tab.id}`"
                    type="radio"
                    :name="nameInput"
                    :value="`${nameInput}-${tab.method}`"
                    :checked="tab.id === 1"
                />
                <label :for="`${nameInput}-${tab.id}`"
                    ><span>{{ tab.name }}</span> <span>{{ tab.time }}</span></label
                >
            </div>
        </div>
        <div class="tabs-text">
            <p><img src="@/assets/img/svg/truck.svg" alt="truck" /> Выберите адрес доставки</p>
        </div>
    </div>
</template>

<script>
export default {
    props: ["nameInput"],
    data() {
        return {
            tabs: [
                { id: 1, name: "Доставка", time: "от 40 мин", method: "delivery" },
                { id: 2, name: "Самовывоз", time: "от 10 мин", method: "pickup" },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: fit-content;
}
.tabs .tabs-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #f4f4f4;
    border-radius: 12px;
    width: 100%;
    padding: 5px;
}
.tabs .tab-item {
    width: 50%;
    height: 34px;
    text-align: center;
}
.tabs .tab-item input[type="radio"] {
    display: none;
}
.tabs .tab-item input[type="radio"]:checked + label {
    background-color: #fff;
    border-radius: 10px;
}
.tabs .tab-item input[type="radio"]:checked + label span:first-child {
    color: var(--green);
}
.tabs .tab-item label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
    height: inherit;
}
.tabs .tab-item label span:first-child {
    font-family: "Nunito Sans SemiBold";
    font-size: 12px;
    line-height: 18px;
    color: #7a7a7a;
    font-family: "Nunito Sans SemiBold";
}
.tabs .tab-item label span:last-child {
    font-family: "Nunito Sans ExtraLight";
    font-size: 8px;
    line-height: 12px;
    color: #bebebe;
    font-family: "Nunito Sans ExtraLight";
}
.tabs .tabs-text {
    font-family: "Nunito Sans ExtraLight";
    font-size: 8px;
    line-height: 12px;
    color: #7a7a7a;
    text-align: center;
    margin-top: 10px;
    font-family: "Nunito Sans ExtraLight";
}
</style>

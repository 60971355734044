<template>
    <button class="btn-to-cart">{{ symbol }}</button>
</template>

<script>
export default {
    props: ["symbol"],
};
</script>

<style lang="scss" scoped>
.btn-to-cart {
    width: 40px;
    height: 40px;
    border-radius: 16px;
    background: var(--green);
    font-size: 18px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #fff;
    &:hover,
    &:focus {
        background: #284e37;
        -webkit-transition: 0.2s ease 0.2s;
        -o-transition: 0.2s ease 0.2s;
        transition: 0.2s ease 0.2s;
        transition: 0.2s ease 0.2s;
    }
    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        border-radius: 12px;
        background: var(--green);
        font-size: 14px;
    }
}
</style>
